import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getApp } from 'firebase/app';
import { HttpClientModule } from '@angular/common/http';
import { CustomPipesModule } from './pipes/pipes.module';
import { Capacitor } from '@capacitor/core';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NewActivityComponentModule } from './components/events/new-activity/new-activity.module';

import { TimeagoModule } from 'ngx-timeago';

import { AuthInterceptor } from './services/auth/auth-interceptor/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InputMaskModule } from '@ngneat/input-mask';
import { WorkoutDetailModule } from './components/workout-detail/workout-detail.module';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { AciScoreDetailsComponent } from './components/aci-score-details/aci-score-details.component';
import { ImageSliderModelComponent } from './components/image-slider-model/image-slider-model.component';
import { NewQuickWorkoutPageModule } from './components/new-quick-workout/new-quick-workout.module';
import { CommonLoadingComponent } from './components/common-loading/common-loading.component';


@NgModule({
  declarations: [AppComponent, NotificationsComponent, AciScoreDetailsComponent, ImageSliderModelComponent, CommonLoadingComponent],
  imports: [
    BrowserModule,
    TimeagoModule.forRoot(),
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    AngularFireMessagingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    HttpClientModule,
    CustomPipesModule,
    NewActivityComponentModule,
    NewQuickWorkoutPageModule,
    InputMaskModule,
    WorkoutDetailModule
  ],
  providers: [Calendar,
    File,
    MediaCapture,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
